export type PageInfoBlockProps = {
    articleId?: string;
    closeDrawer: () => void;
};

export const testIds = {
    pageInfoBlock: "page-info-block",
    readMoreButton: "read-more-button",
    goToArticlesButton: "go-to-articles-button",
};
