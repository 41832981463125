import { ReactNode } from "react";

import { colors } from "lib/palette";

type FontConfig = {
    fontSize: string;
    lineHeight: string;
};

export const FONT_CONFIG: Record<number, FontConfig> = {
    11: { fontSize: "11px", lineHeight: "16px" },
    12: { fontSize: "12px", lineHeight: "18px" },
    13: { fontSize: "13px", lineHeight: "20px" },
    14: { fontSize: "14px", lineHeight: "20px" },
    16: { fontSize: "16px", lineHeight: "24px" },
    18: { fontSize: "18px", lineHeight: "25px" },
    26: { fontSize: "26px", lineHeight: "34px" },
};

export enum Tag {
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
    H4 = "h4",
    H5 = "h5",
    H6 = "h6",
    P = "p",
    SPAN = "span",
}

type TextAlign = "start" | "center" | "end";
type Weight = 400 | 600 | 700 | 800;

export type TextProps = {
    children: string | ReactNode;
    className?: string;
    align?: TextAlign;
    size?: keyof typeof FONT_CONFIG;
    color?: (typeof colors)[keyof typeof colors];
    weight?: Weight;
    tag?: `${Tag}`;
    testId?: string;
    truncate?: boolean;
    title?: string;
    showTitle?: boolean;
};

export type TextStyledProps = {
    align: TextAlign;
    size: keyof typeof FONT_CONFIG;
    color: (typeof colors)[keyof typeof colors];
    weight: Weight;
    truncate?: boolean;
};
