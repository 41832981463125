import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { TFunction } from "next-i18next";

import { Icons } from "components/molecules/icon/icon.interface";

export type Errors = Record<
    number,
    { message: string; showRefetch: boolean; iconName?: Icons }
>;
export interface FallbackQueryProps {
    queries: UseQueryResult<unknown, any>[];
    errors?: Errors;
    errorMessage?: string;
    showProgress?: boolean;
    children?: React.ReactNode;
}

export const testIds = {
    fallbackLoader: "fallback-loader",
    fallbackRingProgress: "fallback-ring-progress",
    fallbackQueryComponent: "fallback-query-component",
};

export const FALLBACK_DEFAULT_ERROR_MESSAGE_KEY =
    "fallbackQuery.description.somethingWentWrong";
export const REFETCH_BUTTON_LABEL_KEY = "fallbackQuery.button.tryAgain";

export const generatePermissionDeniedError = (
    errorCode: number,
    t: TFunction,
) => ({
    [errorCode]: {
        message: t(
            "common:fallbackQuery.description.doNotHaveSufficientAccessRights",
            "You do not have sufficient access rights. Please contact your administrator.",
        ),
        showRefetch: false,
        iconName: Icons.warningYellowIconSvg,
    },
});
