import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { paths } from "lib/constants/paths";
import { Permission } from "lib/constants/permissions";
import { UserType } from "lib/constants/user-type";
import { ReactElement } from "react";

type ConfigType = {
    [key: string]: {
        name?: string;
        icon?: ReactElement;
        links: {
            title: string;
            path: string;
            permissionsToShow: Permission[];
            // If permissionsToShow is empty, show the field for all users.
            // If permissionsToShow contains specific permissions, show the field only for users with those permissions.
        }[];
    }[];
};

export const config: ConfigType = {
    [UserType.AGGREGATOR_USER]: [
        {
            name: "USER MANAGEMENT",
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: "Users",
                    path: paths.USERS,
                    permissionsToShow: [],
                },
                {
                    title: "Roles",
                    path: paths.ROLES,
                    permissionsToShow: [],
                },
                {
                    title: "Setting up accesses",
                    path: paths.USERS_PERMISSIONS,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "PROVIDER MANAGEMENT",
            icon: <Icon name={Icons.leftMenuProviderManagementSvg} />,
            links: [
                {
                    title: "Provider companies",
                    path: paths.PROVIDERS,
                    permissionsToShow: [],
                },
                {
                    title: "Company reports",
                    path: paths.PROVIDER_COMPANY_REPORTS,
                    permissionsToShow: [],
                },
                {
                    title: "Standard reports",
                    path: paths.STANDARD_REPORTS_PROVIDER,
                    permissionsToShow: [],
                },
                {
                    title: "Folders for reports",
                    path: paths.PROVIDER_FOLDERS_FOR_REPORTS,
                    permissionsToShow: [],
                },
                {
                    title: "Games",
                    path: paths.GAMES,
                    permissionsToShow: [],
                },
                {
                    title: "Platform monitoring",
                    path: paths.PLATFORM_MONITORING,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "B2B MANAGEMENT",
            icon: <Icon name={Icons.leftMenuB2bManagementSvg} />,
            links: [
                {
                    title: "B2B companies",
                    path: paths.B2B_COMPANIES,
                    permissionsToShow: [],
                },
                {
                    title: "Standard reports",
                    path: paths.STANDARD_REPORTS_B2B,
                    permissionsToShow: [],
                },
                {
                    title: "Company reports",
                    path: paths.B2B_COMPANY_REPORTS,
                    permissionsToShow: [],
                },
                {
                    title: "Folders for reports",
                    path: paths.B2B_FOLDERS_FOR_REPORTS,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "AGG ANALYTICS",
            icon: <Icon name={Icons.aggAnalyticsSvg} />,
            links: [
                {
                    title: "Company reports",
                    path: paths.AGGREGATOR_COMPANY_REPORTS_IN_FOLDERS_TREE,
                    permissionsToShow: [],
                },
                {
                    title: "Standard reports",
                    path: paths.STANDARD_REPORTS_AGG,
                    permissionsToShow: [],
                },
                {
                    title: "Folders for reports",
                    path: paths.AGG_FOLDERS_FOR_REPORTS,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "OTHER",
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: "Handbooks",
                    path: paths.HANDBOOKS,
                    permissionsToShow: [],
                },
            ],
        },
    ],
    [UserType.B2B_USER]: [
        {
            links: [
                {
                    title: "Start page",
                    path: paths.STARTER_B2B,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "USER MANAGEMENT",
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: "Users",
                    path: paths.USERS,
                    permissionsToShow: [],
                },
                {
                    title: "Roles",
                    path: paths.ROLES,
                    permissionsToShow: [],
                },
                {
                    title: "Setting up accesses",
                    path: paths.USER_ACCESS_COMPANIES,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "PROVIDER MANAGEMENT",
            icon: <Icon name={Icons.leftMenuProviderManagementSvg} />,
            links: [
                {
                    title: "Games",
                    path: paths.GAMES,
                    permissionsToShow: [],
                },
                {
                    title: "Connections",
                    path: paths.CONNECTIONS,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "COMPANY MANAGEMENT",
            icon: <Icon name={Icons.leftMenuCompanyManagementSvg} />,
            links: [
                {
                    title: "Company hierarchy",
                    path: paths.B2B_COMPANIES_HIERARCHY,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "ANALYTICS",
            icon: <Icon name={Icons.aggAnalyticsSvg} />,
            links: [
                {
                    title: "Reports",
                    path: paths.B2B_ANALYTICS_REPORTS,
                    permissionsToShow: [],
                },
                {
                    title: "User accesses",
                    path: paths.B2B_USER_ACCESS_TO_REPORTS,
                    permissionsToShow: [Permission.MANAGE_CLIENT_ANALYTICS],
                },
            ],
        },
    ],
    [UserType.PROVIDER]: [
        {
            name: "USER MANAGEMENT",
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: "Users",
                    path: paths.USERS,
                    permissionsToShow: [],
                },
                {
                    title: "Roles",
                    path: paths.ROLES,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "MONITORING",
            icon: <Icon name={Icons.monitoringSvg} />,
            links: [
                {
                    title: "Platform monitoring",
                    path: paths.PLATFORM_MONITORING,
                    permissionsToShow: [],
                },
            ],
        },
        {
            name: "ANALYTICS",
            icon: <Icon name={Icons.aggAnalyticsSvg} />,
            links: [
                {
                    title: "Reports",
                    path: paths.PROVIDER_ANALYTICS_REPORTS,
                    permissionsToShow: [],
                },
                {
                    title: "User accesses",
                    path: paths.PROVIDER_USER_ACCESS_TO_REPORTS,
                    permissionsToShow: [Permission.MANAGE_CLIENT_ANALYTICS],
                },
            ],
        },
    ],
};
