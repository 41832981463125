import styled from "styled-components";
import { Drawer } from "@mantine/core";

export const StyledDrawer = styled(Drawer)`
    .mantine-Drawer-drawer {
        width: max-content;
    }

    .mantine-Drawer-overlay {
        background-color: rgb(0 0 0 / 15%);
    }
`;
