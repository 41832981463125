import { RingProgress } from "@mantine/core";
import { useTranslation } from "next-i18next";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import {
    FALLBACK_DEFAULT_ERROR_MESSAGE_KEY,
    REFETCH_BUTTON_LABEL_KEY,
    Errors,
    FallbackQueryProps,
    testIds,
    generatePermissionDeniedError,
} from "./fallback-query.interface";
import { Button } from "components/atoms/button/button.component";
import { ApiErrorCode } from "lib/error-handler";
import { StyledContent, StyledInfo } from "./fallback-query.styled";
import { Spinner } from "components/atoms/spinner/spinner.component";
import { Text } from "components/atoms/text/text.component";
import { colors } from "lib/palette";

const DEFAULT_ERROR_CODE = 0;

export const FallbackQuery = ({
    queries,
    errors,
    errorMessage,
    showProgress,
    children,
}: FallbackQueryProps) => {
    const { t } = useTranslation("common");

    const INITIAL_ERRORS: Errors = {
        ...generatePermissionDeniedError(ApiErrorCode.PERMISSION_DENIED, t),
    };

    const handledErrors = errors
        ? { ...INITIAL_ERRORS, ...errors }
        : INITIAL_ERRORS;

    const isFetching = queries.some((query) => query.isFetching);
    const isError = queries.some((query) => query.isError);
    const isSuccess = queries.every((query) => query.isSuccess);

    const queriesWithError = queries.filter((query) => query.isError);

    const errorCodes = queriesWithError.map(
        (query) => query.error?.error?.code || DEFAULT_ERROR_CODE,
    );
    const handledError = errorCodes.find((code) => handledErrors?.[code]);

    const refetchFunctions = queriesWithError.map((query) => query.refetch);
    const handleRefetch = () => {
        refetchFunctions.forEach((refetch) => void refetch());
    };

    let progress = 0;

    if (showProgress) {
        const totalQueries = queries.length;
        const completedQueries = queries.filter(
            ({ status }) => status === "success",
        ).length;

        progress = Math.floor((completedQueries / totalQueries) * 100);
    }

    if (isSuccess && children) {
        return <>{children}</>;
    }

    if (isFetching) {
        return (
            <StyledContent data-testid={testIds.fallbackQueryComponent}>
                {showProgress ? (
                    <RingProgress
                        data-testid={testIds.fallbackRingProgress}
                        thickness={6}
                        size={70}
                        sections={[{ value: progress, color: colors.purple_2 }]}
                        label={<Text align="center">{progress}%</Text>}
                    />
                ) : (
                    <Spinner data-testid={testIds.fallbackLoader}>
                        <Icon name={Icons.loaderSvg} />
                    </Spinner>
                )}
            </StyledContent>
        );
    }

    if (isError && !isFetching) {
        return (
            <StyledContent data-testid={testIds.fallbackQueryComponent}>
                {handledErrors && handledError ? (
                    <>
                        <Icon
                            name={
                                handledErrors[handledError].iconName ||
                                Icons.warningIconSvg
                            }
                        />
                        <StyledInfo>
                            {handledErrors[handledError].message}
                        </StyledInfo>
                        {handledErrors[handledError].showRefetch && (
                            <Button width={100} onClick={handleRefetch}>
                                {t(REFETCH_BUTTON_LABEL_KEY, "Try again")}
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Icon name={Icons.warningIconSvg} />
                        <StyledInfo>
                            {errorMessage ??
                                t(
                                    FALLBACK_DEFAULT_ERROR_MESSAGE_KEY,
                                    "Something went wrong. Try again or contact your administrator.",
                                )}
                        </StyledInfo>
                        <Button width={100} onClick={handleRefetch}>
                            {t(REFETCH_BUTTON_LABEL_KEY, "Try again")}
                        </Button>
                    </>
                )}
            </StyledContent>
        );
    }

    return null;
};
