export type HeaderProps = {
    isActive: boolean;
    isMobile?: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
    onBack?: () => void;
    pageTitle?: string;
    showBackButton?: boolean;
};

export type StyledHeaderBlockProps = {
    isMobile?: boolean;
};

export const testIds = {
    notificationMenuButton: "notification-menu-button",
    profileMenuButton: "profile-menu-button",
    myProfileButton: "my-profile-button",
    logOutButton: "log-out-button",
    desktopBackButton: "desktop-back-button",
};
