import { FC, useCallback, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { useGetZendeskArticleQuery } from "lib/api/hooks/use-get-zendesk-article.hook";
import { PageInfoBlockProps, testIds } from "./page-info-block.interface";
import {
    StyledBody,
    StyledCloseButton,
    StyledContent,
    StyledFooter,
    StyledIframe,
} from "./page-info-block.styled";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";
import { Button } from "components/atoms/button/button.component";
import { ZENDESK_ARTICLE_PATH } from "lib/constants/zendesk";
import { WarningBlock } from "components/molecules/warning-block/warning-block.component";
import { MAIN_ARTICLES_PAGE_ID } from "../../constants/page-info-configs";
import { generatePageInfoIframeDoc } from "../../utils/generate-page-info-iframe-doc/generate-page-info-iframe-doc";

const ZENDESK_ARTICLE_BLOCK_ID = "zendesk-article-block";

export const PageInfoBlock: FC<PageInfoBlockProps> = ({
    articleId,
    closeDrawer,
}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { t } = useTranslation("common");
    const router = useRouter();
    const zendeskArticleQuery = useGetZendeskArticleQuery(articleId || "", {
        enabled: !!articleId,
    });

    const handleSSORedirect = useCallback(
        (path: string) => {
            void router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    return_to: encodeURIComponent(
                        `${process.env.NEXT_PUBLIC_ZENDESK_BASE_URL}${ZENDESK_ARTICLE_PATH}/${path}`,
                    ),
                },
            });
        },
        [router],
    );

    const handleOnReadMoreClick = () => {
        if (articleId) {
            handleSSORedirect(articleId);
        } else {
            handleSSORedirect(MAIN_ARTICLES_PAGE_ID);
        }
    };

    useEffect(() => {
        const handleIframeMessage = (event: MessageEvent) => {
            if (event.source !== iframeRef.current?.contentWindow) {
                return;
            }

            handleSSORedirect(event.data);
        };

        window.addEventListener("message", handleIframeMessage);

        return () => {
            window.removeEventListener("message", handleIframeMessage);
        };
    }, [handleSSORedirect, router, zendeskArticleQuery.isLoading]);

    if (!articleId) {
        return (
            <StyledBody>
                <StyledCloseButton onClick={closeDrawer} />
                <WarningBlock
                    message={t(
                        "pageInfoDrawer.description.noDataWarningMessage",
                        "There is no article for this page. You can search for relevant information in our knowledge base.",
                    )}
                    buttonText={t(
                        "pageInfoDrawer.button.goToArticles",
                        "Go to articles",
                    )}
                    onButtonClick={handleOnReadMoreClick}
                    buttonWidth={127}
                    buttonTestId={testIds.goToArticlesButton}
                />
            </StyledBody>
        );
    }

    return (
        <StyledBody>
            <StyledContent>
                <StyledCloseButton onClick={closeDrawer} />
                <FallbackQuery queries={[zendeskArticleQuery]}>
                    <StyledIframe
                        ref={iframeRef}
                        data-testid={testIds.pageInfoBlock}
                        id={ZENDESK_ARTICLE_BLOCK_ID}
                        srcDoc={generatePageInfoIframeDoc({
                            title: zendeskArticleQuery.data?.article.title,
                            body: zendeskArticleQuery.data?.article.body,
                        })}
                    />
                </FallbackQuery>
            </StyledContent>

            <StyledFooter>
                <Button
                    width={110}
                    onClick={handleOnReadMoreClick}
                    data-testid={testIds.readMoreButton}
                >
                    {t("pageInfoDrawer.button.readMore", "Read more")}
                </Button>
            </StyledFooter>
        </StyledBody>
    );
};
