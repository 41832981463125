import styled from "styled-components";

export const StyledContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
`;

export const StyledMessageBlock = styled.div`
    max-width: 300px;
`;
