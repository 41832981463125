import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { BurgerMenuButtonProps } from "./burger-menu-button.interface";
import { StyledBurgerMenuButton } from "./burger-menu-button.styled";
import { createSignInOnboardingTargetProps, SignInOnboardingTargets } from "components/providers/onboarding/sign-in";

export const BurgerMenuButton = ({ setIsActive }: BurgerMenuButtonProps) => {
    return (
        <StyledBurgerMenuButton
            {...createSignInOnboardingTargetProps(SignInOnboardingTargets.MENU_ICON)}
            onClick={() => setIsActive((prevActiveStatus) => !prevActiveStatus)}
        >
            <Icon name={Icons.lineMenuSvg} />
        </StyledBurgerMenuButton>
    );
};
